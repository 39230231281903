









































































































import _ from 'lodash';
import moment from 'moment-timezone';
import * as Diff2Html from 'diff2html';
import * as Diff from 'diff';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { DataOptions, DataTableHeader } from 'vuetify';
import { NS_ALERTS } from '@/constants/app.constants';
import { Station, StationConfiguration } from '@/models/station.model';
import { FiltersStationConfigurationHistory } from '@/models/states/filters-state.model';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import OptionsStationConfigurationHistory from '@/components/options-panels/OptionsStationConfigurationHistory.component.vue';
import StationConfigService from '@/services/station-config.service';

@Component({
  name: 'StationConfigurationHistoryView',
  components: {
    LoadingBar,
    OptionsStationConfigurationHistory,
  },
})
export default class StationConfigurationHistoryView extends Vue {
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public isFiltersOpen: boolean = true;
  public isLoading: boolean = false;
  public size: number = 0;
  public stationConfigurations: StationConfiguration[] = [];
  public tableOptions: Partial<DataOptions> = {
    page: 1,
    itemsPerPage: 15,
  };
  public headers: DataTableHeader[] = [
    {
      text: 'Applied At',
      align: 'start',
      value: 'parsed.appliedAt',
      width: 170,
    },
    { text: 'Status', value: 'status' },
    { text: 'Applied By', value: 'appliedBy' },
    { text: 'Station', value: 'stationId' },
    { text: 'Change Type', value: 'parsed.type' },
    { text: 'Affected Service', value: 'service' },
    { text: 'Values', value: 'parsed.description' },
    { text: 'Comment', value: 'description' },
    { text: '', value: 'actions' },
  ];
  public viewFileDialogOpen: boolean = false;
  public diffDialogOpen: boolean = false;
  public dialogFileContent: { fileName: string; content: string } | null = null;
  public dialogDiffContent: { fileName: string; content: string } | null = null;
  public diffs: string = '';

  public onFiltersChange(filters: FiltersStationConfigurationHistory) {
    this.tableOptions.page = filters.page;
    this.tableOptions.itemsPerPage = filters.itemsPerPage;
    this.loadData(filters);
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public loadData(filters: FiltersStationConfigurationHistory) {
    this.isLoading = true;
    const atDate = filters.atDate && filters.atTime ? moment(`${filters.atDate} ${filters.atTime}`, 'YYYY-MM-DD HH:mm:ss').valueOf() : null;

    StationConfigService.query(
      _.extend({}, filters, {
        atDate,
        stationIds: filters.stationIds?.length ? filters.stationIds : null,
        statuses: filters.statuses?.length ? filters.statuses : null,
        services: null,
        changeTypes: filters.changeTypes.length ? filters.changeTypes : null,
        textSearch: filters.textSearch ? filters.textSearch : null,
        count: filters.itemsPerPage,
        start: filters.itemsPerPage * (filters.page - 1),
      }),
    ).then(
      (response) => {
        this.isLoading = false;
        this.stationConfigurations = response.elements;
        this.size = response.totalCount;
      },
      () => {
        this.isLoading = false;
        this.stationConfigurations = [];
        this.size = 0;
      },
    );
  }

  public openViewFileModal(stationConfiguration: StationConfiguration) {
    this.dialogFileContent = {
      fileName: stationConfiguration.service === 'UNIFIED' ? '' : stationConfiguration.newConfigFile.fileName,
      content:
        stationConfiguration.service === 'UNIFIED'
          ? JSON.stringify(stationConfiguration.newConfig, null, 2)
          : stationConfiguration.newConfigFile.contents,
    };
    this.viewFileDialogOpen = true;
  }

  public closeViewFileModal() {
    this.viewFileDialogOpen = false;
    this.dialogFileContent = null;
  }

  public openDiffModal(stationConfiguration: StationConfiguration) {
    let diffs = '';
    if (stationConfiguration.service === 'UNIFIED') {
      diffs =
        stationConfiguration.oldConfig && stationConfiguration.newConfig
          ? Diff.createTwoFilesPatch(
              ' ',
              ' ',
              JSON.stringify(stationConfiguration.oldConfig, null, 2),
              JSON.stringify(stationConfiguration.newConfig, null, 2),
            )
          : '';
    } else {
      diffs = Diff.createTwoFilesPatch(
        stationConfiguration.oldConfigFile ? stationConfiguration.oldConfigFile.fileName : stationConfiguration.newConfigFile.fileName,
        stationConfiguration.newConfigFile.fileName,
        stationConfiguration.oldConfigFile ? stationConfiguration.oldConfigFile.contents : '',
        stationConfiguration.newConfigFile.contents,
      );
    }
    this.dialogDiffContent = {
      fileName: stationConfiguration.service === 'UNIFIED' ? '' : stationConfiguration.newConfigFile.fileName,
      content: diffs,
    };
    this.diffDialogOpen = true;
  }

  public renderDiffs(diffs: any) {
    return Diff2Html.html(diffs, {
      drawFileList: true,
      matching: 'lines',
      outputFormat: 'side-by-side',
    });
  }

  public closeDiffModal() {
    this.dialogDiffContent = null;
    this.diffDialogOpen = false;
  }
}
