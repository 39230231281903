






















































































































import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DataOptions, DataTableHeader } from 'vuetify';
import { NS_ALERTS, NS_STATIONS } from '@/constants/app.constants';
import { ServiceVersion, ServiceVersionApply, Station } from '@/models/station.model';
import { FiltersStationConfiguration } from '@/models/states/filters-state.model';
import StationConfigService from '@/services/station-config.service';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import OptionsStationConfiguration from '@/components/options-panels/OptionsStationConfiguration.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';

@Component({
  name: 'ServiceVersionsView',
  components: {
    ConfirmationDialog,
    LoadingBar,
    OptionsStationConfiguration,
    StationsSelectTree,
  },
})
export default class ServiceVersionsView extends Vue {
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public isLoading: boolean = false;
  public isFiltersOpen: boolean = true;
  public isApplyLoading: boolean = false;
  public applyDialogOpen: boolean = false;
  public removeDialogOpen: boolean = false;
  public size: number = 0;
  public serviceVersions: ServiceVersion[] = [];
  public tableOptions: Partial<DataOptions> = {
    page: 1,
    itemsPerPage: 15,
  };
  public headers: DataTableHeader[] = [
    {
      text: 'Version',
      align: 'start',
      value: 'version',
    },
    { text: 'Created At', value: 'parsed.createdAt', width: 170 },
    { text: 'Created By', value: 'createdBy' },
    { text: 'Service', value: 'service' },
    { text: 'Comment', value: 'description', width: 500 },
    { text: 'Applied On', value: 'appliedOn', width: 300 },
    { text: '', value: 'actions', align: 'end', width: 140 },
  ];
  public availableStations: Station[] = [];
  public applyServiceVersion: ServiceVersionApply = {
    stationServiceVersionId: 0,
    stationIds: [],
    description: '',
    version: '',
  };
  public currentFilters: FiltersStationConfiguration | null = null;
  public selectedServiceVersionForRemove: ServiceVersion | null = null;

  public onFiltersChange(filters: FiltersStationConfiguration) {
    this.currentFilters = filters;
    this.tableOptions.page = filters.page;
    this.tableOptions.itemsPerPage = filters.itemsPerPage;
    this.loadData(filters);
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public loadData(filters: FiltersStationConfiguration) {
    this.isLoading = true;
    StationConfigService.queryServiceVersions(
      _.extend({}, filters, {
        stationIds: filters.stationIds?.length ? filters.stationIds : null,
        statuses: filters.statuses?.length ? filters.statuses : null,
        services: filters.services?.length ? filters.services : null,
        textSearch: filters.textSearch.length ? filters.textSearch : null,
        count: filters.itemsPerPage,
        start: filters.itemsPerPage * (filters.page - 1),
      }),
    ).then(
      (response) => {
        this.isLoading = false;
        this.serviceVersions = response.elements;
        this.size = response.totalCount;
      },
      () => {
        this.isLoading = false;
        this.serviceVersions = [];
        this.size = 0;
      },
    );
  }

  public apply() {
    this.isApplyLoading = true;
    StationConfigService.applyServiceVersions(this.applyServiceVersion).then(
      () => {
        this.isApplyLoading = false;
        this.closeApplyModal();
        this.addAlert({
          type: 'success',
          header: 'Service Version applied successfully',
          message: 'List is being refreshed',
          timeout: 5000,
        });
        if (this.currentFilters) {
          this.loadData(this.currentFilters);
        }
      },
      (errorEvent: ErrorEvent) => {
        this.isApplyLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem applying the Service Version',
          message: _.get(errorEvent, 'response.data.message'),
          timeout: 5000,
        });
      },
    );
  }

  public openApplyModal(serviceVersion: ServiceVersion) {
    this.applyDialogOpen = true;
    const stationsWhereConfigIsActive = serviceVersion.appliedOn.filter((item) => item.status === 'ACTIVE').map((item) => item.stationId);
    this.availableStations = _(this.stations)
      .reject((item) => _.includes(stationsWhereConfigIsActive, item.stationId))
      .value();
    this.applyServiceVersion = {
      stationServiceVersionId: serviceVersion.id,
      version: serviceVersion.version,
      stationIds: [],
      description: '',
    };
  }

  public closeApplyModal() {
    this.applyDialogOpen = false;
    this.availableStations = [];
    this.applyServiceVersion = {
      stationServiceVersionId: 0,
      version: '',
      stationIds: [],
      description: '',
    };
  }

  public remove(serviceVersion: ServiceVersion) {
    this.selectedServiceVersionForRemove = serviceVersion;
    this.removeDialogOpen = true;
  }

  public onRemoveCancel() {
    this.selectedServiceVersionForRemove = null;
  }

  public onRemove() {
    if (this.selectedServiceVersionForRemove) {
      StationConfigService.removeServiceVersion(this.selectedServiceVersionForRemove).then(
        () => {
          this.addAlert({
            type: 'success',
            header: 'Service Version removed successfully',
            message: 'List is being refreshed',
            timeout: 5000,
          });
          if (this.currentFilters) {
            this.loadData(this.currentFilters);
          }
        },
        (error: ErrorEvent) => {
          this.addAlert({
            type: 'error',
            header: 'There was a problem removing the Service Version',
            message: error.message,
            timeout: 5000,
          });
        },
      );
    }
  }
}
