var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('OptionsStationConfiguration',{staticClass:"mb-3",attrs:{"is-open":_vm.isFiltersOpen,"show-services":true,"table-options":_vm.tableOptions},on:{"on-filters-changed":_vm.onFiltersChange,"toggle":_vm.onFiltersToggle}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-10",class:_vm.isFiltersOpen ? 'service-versions-list-table' : 'service-versions-list-table--tight',attrs:{"headers":_vm.headers,"items":_vm.serviceVersions,"loading":_vm.isLoading,"options":_vm.tableOptions,"server-items-length":_vm.size,"loading-text":"Loading... Please wait","fixed-header":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.appliedOn",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.appliedOn),function(version){return _c('v-tooltip',{key:version.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":""}},'v-chip',attrs,false),on),[(version.parsed.icon)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":version.parsed.color,"small":""}},[_vm._v(" "+_vm._s(version.parsed.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(version.stationId)+" ")],1)]}}],null,true)},[_c('span',[_c('strong',[_vm._v("Applied At:")]),_vm._v(" "+_vm._s(_vm._f("getDateTime")(version.appliedAt)))]),_vm._v(" "),_c('br'),_c('span',[_c('strong',[_vm._v("Applied By:")]),_vm._v(" "+_vm._s(version.appliedBy))]),_vm._v(" "),_c('br'),(version.becameActiveAt)?_c('span',[_c('strong',[_vm._v("Became active at:")]),_vm._v(" "+_vm._s(_vm._f("getDateTime")(version.becameActiveAt)))]):_vm._e(),(version.becameActiveAt)?_c('br'):_vm._e(),(version.becameUnusedAt)?_c('span',[_c('strong',[_vm._v("Became unused at:")]),_vm._v(" "+_vm._s(_vm._f("getDateTime")(version.becameUnusedAt)))]):_vm._e(),(version.becameUnusedAt)?_c('br'):_vm._e(),(version.description)?_c('span',[_c('strong',[_vm._v("Description:")]),_vm._v(" "+_vm._s(_vm._f("textEllipsis")(version.description,200)))]):_vm._e()])}),1)]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","link":"","to":{ name: 'station-service-configuration', query: { services: [item.service] } }}},[_vm._v(" "+_vm._s(item.service)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openApplyModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-restart")])],1)]}}],null,true)},[_c('span',[_vm._v("Apply")])]),(!item.appliedOn)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()]}}])})],1)],1),_c('ConfirmationDialog',{attrs:{"open":_vm.removeDialogOpen,"message":_vm.selectedServiceVersionForRemove
        ? ("Are you sure you want to remove this version? <br /> Version: " + (_vm.selectedServiceVersionForRemove.version))
        : ''},on:{"update:open":function($event){_vm.removeDialogOpen=$event},"onConfirm":_vm.onRemove,"onCancel":_vm.onRemoveCancel}}),_c('v-dialog',{attrs:{"max-width":"480","persistent":""},model:{value:(_vm.applyDialogOpen),callback:function ($$v) {_vm.applyDialogOpen=$$v},expression:"applyDialogOpen"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Apply Service Version ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('v-card-text',[_c('v-text-field',{staticClass:"mb-4",attrs:{"value":_vm.applyServiceVersion.version,"prepend-icon":"mdi-tag-outline","label":"Version","disabled":""}}),_c('StationsSelectTree',{attrs:{"model":_vm.applyServiceVersion.stationIds,"stations":_vm.availableStations},on:{"update:model":function($event){return _vm.$set(_vm.applyServiceVersion, "stationIds", $event)}}}),_c('ValidationProvider',{attrs:{"name":"Comments","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var touched = ref.touched;
        var valid = ref.valid;
return [_c('v-textarea',{attrs:{"label":"Comments","prepend-icon":"mdi-comment-account-outline","messages":touched || valid ? '' : 'A comment is required',"error-messages":errors},model:{value:(_vm.applyServiceVersion.description),callback:function ($$v) {_vm.$set(_vm.applyServiceVersion, "description", $$v)},expression:"applyServiceVersion.description"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeApplyModal}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid || !_vm.applyServiceVersion.stationIds.length},on:{"click":_vm.apply}},[_vm._v(" Apply ")])],1)]}}])}),_c('LoadingBar',{attrs:{"is-loading":_vm.isApplyLoading}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }