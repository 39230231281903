






































import _ from 'lodash';
import { DataOptions } from 'vuetify';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NS_STATIONS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { FiltersStationConfiguration } from '@/models/states/filters-state.model';
import { QueryService } from '@/services/query.service';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';
import SimpleSelect from '@/components/shared/SimpleSelect.component.vue';
import { Options } from '@/models/app.model';
import {
  STATION_CONFIGURATION_SERVICES,
  STATION_CONFIGURATION_STATUSES,
  STATION_CONFIGURATION_TYPES,
} from '@/constants/station-configuration.constants';

@Component({
  name: 'OptionsStationConfiguration',
  components: {
    StationsSelectTree,
    SimpleSelect,
  },
})
export default class OptionsStationConfiguration extends Vue {
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Prop() public tableOptions?: Partial<DataOptions>;
  @Prop({ default: true }) public isOpen!: number;
  @Prop({ default: false }) public showServices?: boolean;

  public filters: Partial<FiltersStationConfiguration> = {
    stationIds: [],
    textSearch: '',
    statuses: [],
    itemsPerPage: 15,
    services: [],
    page: 1,
  };

  public ignoreFirstTableOptionsSet: boolean = true;
  public isOpenInner: number | undefined = 0;
  public statuses: Options<string>[] = _.clone(STATION_CONFIGURATION_STATUSES);
  public types: Options<string>[] = _.clone(STATION_CONFIGURATION_TYPES);
  public services: Options<string>[] = _.clone(STATION_CONFIGURATION_SERVICES).filter((c) => c.value !== 'UNIFIED');

  public mounted() {
    this.applyUrlParams();
    this.fetchStations();
  }

  @Emit()
  public onFiltersChanged(): Partial<FiltersStationConfiguration> {
    return this.filters;
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  @Watch('tableOptions')
  public onTableOptionsChange() {
    if (this.ignoreFirstTableOptionsSet) {
      this.ignoreFirstTableOptionsSet = false;
      return;
    }
    if (this.tableOptions) {
      this.filters.page = this.tableOptions.page;
      this.filters.itemsPerPage = this.tableOptions.itemsPerPage;
      this.updateQueryParams();
    }
  }

  public resetPage() {
    this.filters.page = 1;
  }

  public applyFilters() {
    this.onFiltersChanged();
    this.updateQueryParams();
  }

  public updateQueryParams() {
    const newQueryParams = _.omit(this.filters, ['date', 'time']);
    const queryParams = _.omit(this.getQueryParams(), ['date', 'time']);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.filters = this.getQueryParams();
    }

    this.applyFilters();
  }

  private getQueryParams(): Partial<FiltersStationConfiguration> {
    if (!_.isEmpty(this.$route.query)) {
      return {
        stationIds: QueryService.getStations(this.$route) || [],
        textSearch: (this.$route.query.textSearch as string) || '',
        statuses: QueryService.getOptions(this.$route, 'statuses') || [],
        services: QueryService.getOptions(this.$route, 'services') || [],
        itemsPerPage: this.$route.query.itemsPerPage ? parseInt(this.$route.query.itemsPerPage as string, 10) : 15,
        page: this.$route.query.page ? parseInt(this.$route.query.page as string, 10) : 1,
      };
    }
    return {};
  }
}
