




















































































































































import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { DataOptions, DataTableHeader } from 'vuetify';
import { NS_ALERTS } from '@/constants/app.constants';
import { FiltersStationConfiguration } from '@/models/states/filters-state.model';
import { Station, StationConfigurationFile, StationConfigurationFileApply } from '@/models/station.model';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import OptionsStationConfiguration from '@/components/options-panels/OptionsStationConfiguration.component.vue';
import StationConfigService from '@/services/station-config.service';

@Component({
  name: 'StationServiceConfigurationFilesView',
  components: {
    ConfirmationDialog,
    LoadingBar,
    OptionsStationConfiguration,
  },
})
export default class StationServiceConfigurationFilesView extends Vue {
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public isFiltersOpen: boolean = true;
  public isLoading: boolean = false;
  public isApplyLoading: boolean = false;
  public dialog: boolean = false;
  public removeDialogOpen: boolean = false;
  public viewFileDialogOpen: boolean = false;
  public size: number = 0;
  public configurationFiles: StationConfigurationFile[] = [];
  public tableOptions: Partial<DataOptions> = {
    page: 1,
    itemsPerPage: 15,
  };
  public headers: DataTableHeader[] = [
    {
      text: 'Version',
      align: 'start',
      value: 'version',
    },
    { text: 'Created At', value: 'parsed.createdAt', width: 170 },
    { text: 'Created By', value: 'createdBy' },
    { text: 'Station', value: 'stationId' },
    // { text: 'Service', value: 'service' },
    // { text: 'File', value: 'fileName' },
    { text: 'Hash', value: 'parsed.hashShort' },
    { text: 'Comment', value: 'description', width: 200 },
    { text: 'Applied On', value: 'appliedOn', width: 300 },
    { text: '', value: 'actions', align: 'end', width: 120 },
  ];
  public applyStationConfigurationFile: StationConfigurationFileApply = {
    stationConfigFileId: 0,
    stationId: '',
    description: '',
    version: '',
    contents: '',
  };
  public currentFilters: FiltersStationConfiguration | null = null;
  public selectedFileForRemove: StationConfigurationFile | null = null;
  public selectedFileForView: StationConfigurationFile | null = null;

  public onFiltersChange(filters: FiltersStationConfiguration) {
    this.currentFilters = filters;
    this.tableOptions.page = filters.page;
    this.tableOptions.itemsPerPage = filters.itemsPerPage;
    this.loadData(filters);
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public loadData(filters: FiltersStationConfiguration) {
    this.isLoading = true;
    StationConfigService.queryStationConfigurationFiles(
      _.extend({}, filters, {
        stationIds: filters.stationIds?.length ? filters.stationIds : null,
        statuses: filters.statuses?.length ? filters.statuses : null,
        textSearch: filters.textSearch.length ? filters.textSearch : null,
        count: filters.itemsPerPage,
        start: filters.itemsPerPage * (filters.page - 1),
      }),
    ).then(
      (response) => {
        this.isLoading = false;
        this.configurationFiles = response.elements;
        this.size = response.totalCount;
      },
      (e) => {
        this.isLoading = false;
        this.configurationFiles = [];
        this.size = 0;
      },
    );
  }

  public apply() {
    this.isApplyLoading = true;
    StationConfigService.applyStationConfigurationFile(this.applyStationConfigurationFile).then(
      () => {
        this.isApplyLoading = false;
        this.closeApplyModal();
        this.addAlert({
          type: 'success',
          header: 'Configuration File applied successfully',
          message: 'List is being refreshed',
          timeout: 5000,
        });
        if (this.currentFilters) {
          this.loadData(this.currentFilters);
        }
      },
      (error: ErrorEvent) => {
        this.isApplyLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem applying the Configuration File',
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }

  public openViewFileModal(configurationFile: StationConfigurationFile) {
    this.selectedFileForView = configurationFile;
    if (_.isObject(this.selectedFileForView.contents)) {
      this.selectedFileForView.contents = JSON.stringify(this.selectedFileForView.contents, null, 2);
    }
    this.viewFileDialogOpen = true;
  }

  public closeViewFileModal() {
    this.viewFileDialogOpen = false;
  }

  public openApplyModal(configurationFile: StationConfigurationFile) {
    this.dialog = true;
    this.applyStationConfigurationFile = {
      stationConfigFileId: configurationFile.id,
      version: configurationFile.version,
      stationId: configurationFile.stationId,
      description: '',
      contents: configurationFile.contents,
    };
  }

  public closeApplyModal() {
    this.dialog = false;
    this.applyStationConfigurationFile = {
      stationConfigFileId: 0,
      stationId: '',
      description: '',
      version: '',
      contents: '',
    };
  }

  public remove(configurationFile: StationConfigurationFile) {
    this.selectedFileForRemove = configurationFile;
    this.removeDialogOpen = true;
  }

  public onRemoveCancel() {
    this.selectedFileForRemove = null;
  }

  public onRemove() {
    if (this.selectedFileForRemove) {
      StationConfigService.removeStationConfigurationFile(this.selectedFileForRemove).then(
        () => {
          this.addAlert({
            type: 'success',
            header: 'File removed successfully',
            message: 'List is being refreshed',
            timeout: 5000,
          });
          if (this.currentFilters) {
            this.loadData(this.currentFilters);
          }
        },
        (error: ErrorEvent) => {
          this.addAlert({
            type: 'error',
            header: 'There was a problem removing the file',
            message: error.message,
            timeout: 5000,
          });
        },
      );
    }
  }
}
