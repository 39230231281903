



















































import _, { sortBy } from 'lodash';
import moment from 'moment-timezone';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import DisplayIntervalSelect from '@/components/shared/DisplayIntervalSelect.component.vue';

@Component({
  name: 'DateRangeSelector',
  components: {
    DisplayIntervalSelect,
  },
})
export default class DateRangeSelector extends Vue {
  @Prop() public startDate!: string;
  @Prop() public endDate!: string;
  @Prop() public disabled?: boolean;
  @Prop({ default: true }) public range?: boolean;
  @Prop({ default: false }) public clearable?: boolean;
  @Prop({ default: 'Date Range' }) public label?: string;

  public dialogToggle: boolean = false;
  public dialogTab: number = 0;
  public innerDate: any = [];

  public mounted() {
    this.onStartDateChange();
    this.onEndDateChange();
  }

  public onClear() {
    if (this.range) {
      this.innerDate = [];
    } else {
      this.innerDate = '';
    }

    this.onInnerDateChange();
  }

  @Emit()
  public onChanged() {
    return this.innerDate;
  }

  @Watch('startDate')
  public onStartDateChange() {
    if (this.range) {
      this.$set(this.innerDate, 0, _.clone(this.startDate));
    } else {
      this.innerDate = _.clone(this.startDate);
    }
  }

  @Watch('endDate')
  public onEndDateChange() {
    if (this.range) {
      this.$set(this.innerDate, 1, _.clone(this.endDate));
    }
  }

  public onInnerDateChange() {
    if (this.range) {
      this.innerDate = sortBy(this.innerDate);
      this.$emit('update:startDate', this.innerDate[0]);
      this.$emit('update:endDate', this.innerDate[1]);
    } else {
      this.$emit('update:startDate', this.innerDate);
    }
    this.onChanged();
  }

  public getHumanDate(date: string) {
    return moment(date).format('ddd, MMM DD');
  }
}
