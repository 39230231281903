var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('OptionsStationConfigurationHistory',{staticClass:"mb-3",attrs:{"is-open":_vm.isFiltersOpen,"table-options":_vm.tableOptions},on:{"on-filters-changed":_vm.onFiltersChange,"toggle":_vm.onFiltersToggle}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-10",class:_vm.isFiltersOpen ? 'config-history-list-table' : 'config-history-list-table--tight',attrs:{"headers":_vm.headers,"items":_vm.stationConfigurations,"loading":_vm.isLoading,"options":_vm.tableOptions,"server-items-length":_vm.size,"loading-text":"Loading... Please wait","fixed-header":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.parsed.color,"outlined":true}},'v-chip',attrs,false),on),[(item.parsed.icon)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.parsed.color,"small":""}},[_vm._v(_vm._s(item.parsed.icon))]):_vm._e(),_vm._v(" "+_vm._s(item.parsed.status)+" ")],1)]}}],null,true)},[(item.becameActiveAt)?_c('span',[_c('strong',[_vm._v("Became active at:")]),_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.becameActiveAt)))]):_vm._e(),(item.failedAt)?_c('span',[_c('strong',[_vm._v("Applying failed at:")]),_vm._v(" "+_vm._s(_vm._f("getDateTime")(item.failedAt)))]):_vm._e()])]}},{key:"item.parsed.description",fn:function(ref){
var item = ref.item;
return [_c('section',[_vm._v(" "+_vm._s(item.parsed.description)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('section',{staticClass:"col--justify-end"},[(item.type === 'SERVICE_CONFIG_FILE' && (item.newConfigFile || item.newConfig))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openViewFileModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-code-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]):_vm._e(),(item.type === 'SERVICE_CONFIG_FILE' && (item.newConfigFile || item.newConfig))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.openDiffModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vector-difference")])],1)]}}],null,true)},[_c('span',[_vm._v("Diff")])]):_vm._e()],1)]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","link":"","to":{ name: 'station-service-configuration', query: { services: [item.service] } }}},[_vm._v(" "+_vm._s(item.service)+" ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"680","persistent":""},model:{value:(_vm.viewFileDialogOpen),callback:function ($$v) {_vm.viewFileDialogOpen=$$v},expression:"viewFileDialogOpen"}},[(_vm.dialogFileContent)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" View File "),(_vm.dialogFileContent.fileName)?_c('span',[_vm._v("("+_vm._s(_vm.dialogFileContent.fileName)+")")]):_vm._e()]),_c('v-card-text',[_c('highlightjs',{staticClass:"mb-5",staticStyle:{"height":"400px","overflow":"auto"},attrs:{"autodetect":"","code":_vm.dialogFileContent.content}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeViewFileModal}},[_vm._v(" Close ")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"1080","light":""},model:{value:(_vm.diffDialogOpen),callback:function ($$v) {_vm.diffDialogOpen=$$v},expression:"diffDialogOpen"}},[(_vm.dialogDiffContent)?_c('v-card',[(_vm.dialogDiffContent.fileName)?_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.dialogDiffContent.fileName)+" ")]):_vm._e(),_c('v-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderDiffs(_vm.dialogDiffContent.content))}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeDiffModal}},[_vm._v(" Close ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }